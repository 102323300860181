module.exports = {
  defaultTitle: "Do It Best Industrial Supply",
  logo: "https://doitbestindustrialsupply.com/favicon/favicon-512.png",
  author: "Do It Best Industrial Supply",
  url: "https://doitbestindustrialsupply.com",
  legalName: "Do It Best Industrial Supply",
  defaultDescription:
    "Do It Best Industrial Supply is a hardware store in Calvert, AL that sells home hardware, tools, garden supplies, lumbar, and supplies the industrial markets. We offer lumbar and pipe/chain cutting, key cutting, and carry a variety of paint supplies.",
  socialLinks: {
    // twitter: "http://www.twitter.com/doitbestindustrialsupply",
    // youtube: "https://www.youtube.com/user/doitbestindustrialsupply",
    Facebook: 'https://www.facebook.com/Do-It-Best-Industrial-Supply-100534645668023',
  },
  // googleAnalyticsID: 'UA-88875900-4',
  themeColor: "​#7a2016",
  backgroundColor: "#107c80",
  social: {
    facebook: "appId",
    twitter: "@doitbestindustrialsupply",
  },
  address: {
    street: "19310 HWY 43",
    city: "Mt Vernon",
    region: "Alabama",
    country: "USA",
    zipCode: "36560",
  },
  contact: {
    email: "industrialsupply.llc.com@gmail.com",
    phone: "251-829-4591",
  },
  foundingDate: "2019",
}
