/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import Thumbnail from "../images/dibis/Do It Best Logo Wide.png"
import {
  url,
  defaultDescription,
  social,
  defaultTitle,
  socialLinks,
  address,
  contact,
  legalName,
  logo,
} from "../data/config"

const SEO = ({
  title = defaultTitle,
  description = defaultDescription,
  location = "",
}) => {
  const structuredDataOrganization = `{ 
		"@context": "http://schema.org",
		"@type": "Store",
		"name": "${legalName}",
		"url": "${url}",
		"logo": "${logo}",
    "image": "https://doitbestindustrialsupply.com/static/81f2419b645243cbaf2c7a86a7b9d16f/76ff1/Do%20It%20Best%20Logo%20Wide.png",
		"telephone": "${contact.phone}",
		"address": {
			"@type": "PostalAddress",
      "streetAddress": "${address.street}",
			"addressLocality": "${address.city}",
			"addressRegion": "${address.region}",
			"addressCountry": "${address.country}",
			"postalCode": "${address.zipCode}"
		},
		"sameAs": [
			"${socialLinks.Facebook}"
		],
    "openingHoursSpecification": [
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday"
        ],
        "opens": "07:00",
        "closes": "17:00"
      }
    ]
  	}`

  return (
    <Helmet>
      <meta name="description" content={description} />
      <meta name="image" content={Thumbnail} />

      <meta property="og:url" content={`${url}${location}/`} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={Thumbnail} />
      <meta property="fb:app_id" content={social.facebook} />
      <meta
        name="facebook-domain-verification"
        content="hjpf8dhx3zfve3zaolh3b40tpsmm14"
      />

      <meta name="twitter:card" content="summary" />
      {/* <meta name="twitter:creator" content={socialLinks.twitter} />
      <meta name="twitter:site" content={social.twitter} /> */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image:src" content={Thumbnail} />
      <script type="application/ld+json">{structuredDataOrganization}</script>
      {/* <link rel="publisher" href={socialLinks.google} /> */}
      <title>{title}</title>
      <html lang="en" dir="ltr" />
    </Helmet>
  )
}

export default SEO
